import React from "react";

import { Box, Span } from "../Core";

export const CardSidebar = ({ children, ...rest }) => (
  <Box css="background: #07131e;" borderRadius="17px;"p="40px" {...rest}>
    {children}
  </Box>
);

export const Block = ({ children, ...rest }) => (
  <Box borderBottom="1px solid #07131e" pt="20px" pb="13px" {...rest}>
    {children}
  </Box>
);

export const DateText = ({ link = "/", children, ...rest }) => (
  <p className="white-color">
    {children}
  </p>
);

